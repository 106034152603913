import { gql } from '@apollo/client';
const GET_LIST = gql`
query Get_List ($listid: Int!, $arg1: String) {
getSiteList(listid: $listid, arg1: $arg1){

id,
inid,
title,
itemstatus,
gender,
email,
userid,
fieldtype,
masterid,
passowrd,
confirmpassword,
required,

description,
itemId,
status,

zip,
planid, 
phone, 

address,
city,
total,
country,
fullname

province,
date,
domain,
username,


addnew,
tbl,
rowid,

string1,
string2,
string3,
string4,
string5,

string6,
string7,
string8,
string9,
string10,

string11,
string12,
string13,
string14,
string15,

string16,
string17,
string18,
string19,
string20,

string21,
string22,
string23,
string24,
string25,

string26,
string27,	

name,
typeid,
subone {
s1itemId
s1inid
s1description
s1title
s1status
s1id
}
}
}
`;

const RESET_PASSWORD = gql`
  query Password($username: String!, $domain: String, $otp: String!, $password: String!, $confirmpassword: String!, $frmMode: Int!) {
    password(username: $username, domain: $domain, otp: $otp, password: $password, confirmpassword: $confirmpassword, frmMode:$frmMode) {
      msg
      status
    }
  }
`;
export { GET_LIST, RESET_PASSWORD }
