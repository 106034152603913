import React, {useState, useEffect } from 'react';
import './form.css';
//import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, gql, useQuery } from '@apollo/client';
import { GET_LIST } from '../../components/gpl/gpl';
import jwt_decode from "jwt-decode";
import SyncIcon from '@mui/icons-material/Sync';
//import FrmInput from '../../components/input/input';
//import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Switch from '@mui/joy/Switch';
import { useParams} from 'react-router-dom';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Card from '@mui/joy/Card';
//import TextField from '@mui/material/TextField';
//import Typography from '@mui/joy/Typography';
//import Stack from '@mui/joy/Stack';
//import Typography from '@mui/joy/Typography';

var token = "none";
var apiToken = "none";
if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");
} 

const CREATE_MUTATION = gql`mutation AddRecord($input: FormInput) {
addRecord(input: $input) {
msg,
status,
itemId,
typeid,
address
}
}
`; 

function SelectList(props) {
const { loading, error, data } = useQuery(GET_LIST, { variables: { listid: parseInt(props.id) }, fetchPolicy: "cache-first"});
if (loading) return "Loading...";
if (error) return `Error! ${error.message}`; 
if (data) {
const numbers = data.getSiteList;
const listItems = numbers.map(number =>
<option key={"listfields"+number.id} value={number.string1}>{number.title}</option>);
return ( <><option value=""> --Select-- </option>
{listItems}</>); }}


function GetDataFieldList(props) {
  const { loading, error, data } = useQuery(GET_LIST, { variables: { listid: 39, arg1:'-'+props.id }, fetchPolicy: "cache-first"});
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`; 
  if (data) {
  const numbers = data.getSiteList;
  const listItems = numbers.map(number =>
  <option key={"GetDataFieldList"+number.id} value={(number.inid != 0) ? number.inid : number.itemId}>{(number.inid != 0) ? number.inid : number.itemId} {number.title}</option>);
  return ( <div className='form-floating'><select placeholder={props.placeholder} required={true} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
  <option value=''>-- {props.value} Select --</option>
    {listItems}
</select>
<label htmlFor={props.name}>{props.placeholder} *</label>
</div>
); }}

   

function GetDataSetList(props) {
  const { loading, error, data } = useQuery(GET_LIST, { variables: { listid: 33 }, fetchPolicy: "cache-first"});
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`; 
  if (data) {
  const numbers = data.getSiteList;
  const listItems = numbers.map(number =>
  <option key={"dataset"+number.id} value={(number.inid != 0) ? number.inid : number.itemId}>{number.title} {props.id}</option>);
  return ( 
  <div className='form-floating'><select placeholder='Dataset' required={true} className="form-control" id="zip" name="zip" value={props.value} onChange={props.handleChange} >
  <option value=''>-- Select --</option>
    {listItems}
</select>
<label htmlFor={'zip'}>Dataset *</label>
</div>
); }}


export default function GetForm(props){
var frmname = props.formmode+"frm"+props.viewid;
const [formState, setFormState] = useState({
fullname: ((typeof props.fullname !== 'undefined') ? props.fullname : ""),
gender: ((typeof props.gender !== 'undefined') ? parseInt(props.gender) : 0),
username: "",
frmtype:((typeof props.formmode !== 'undefined') ? parseInt(props.formmode) : 1),
formid:(typeof props.viewid != 'undefined') ? parseInt(props.viewid) : 0,
status:((typeof props.status != 'undefined') ? parseInt(props.status) : 0),
description:((typeof props.description !== 'undefined') ? props.description : ""),

password:"",
confirmPassword:"",
address: ((typeof props.address !== 'undefined') ? props.address : ""),
city:((typeof props.city !== 'undefined') ? props.city : ""),
province:((typeof props.province !== 'undefined') ? props.province : ""),
zip:((typeof props.zip !== 'undefined') ? props.zip : ""),
country:((typeof props.country !== 'undefined') ? parseInt(props.country) : 0),
itemstatus:((typeof props.status !== 'undefined' && parseInt(props.status) === 1) ? 1 : 0),
rowid:((typeof props.rowid !== 'undefined') ? props.rowid : 0),
tbl:((typeof props.tbl !== 'undefined') ? parseInt(props.tbl) : 0),
phone:((typeof props.phone !== 'undefined') ? props.phone : ""),
title:((typeof props.title !== 'undefined') ? props.title : ""),
id:((typeof props.id !== 'undefined') ? parseInt(props.id) : 0),
planid:((typeof props.planid !== 'undefined') ? parseInt(props.planid) : 0),
uuid:((typeof props.uuid != 'undefined') ? props.uuid : ""),
total:((typeof props.total !== 'undefined') ? props.total : 0),
string1:((typeof props.string1 !== 'undefined') ? props.string1 : ''),
string2:((typeof props.string2 !== 'undefined') ? props.string2 : ''),
string3:((typeof props.string3 !== 'undefined') ? props.string3 : ''),
string4:((typeof props.string4 !== 'undefined') ? props.string4 : ''),
string5:((typeof props.string5 !== 'undefined') ? props.string5 : ''),
string6:((typeof props.string6 !== 'undefined') ? props.string6 : ''),
string7:((typeof props.string7 != 'undefined') ? props.string7 : ''),
string8:((typeof props.string8 !== 'undefined') ? props.string8 : ''),
string9:((typeof props.string9 !== 'undefined') ? props.string9 : ''),
string10:((typeof props.string10 !== 'undefined') ? props.string10 : ''),
string11:((typeof props.string11 !== 'undefined') ? props.string11 : ''),
string12:((typeof props.string12 !== 'undefined') ? props.string12 : ''),
string13:((typeof props.string13 !== 'undefined') ? props.string13 : ''),
string14:((typeof props.string14 !== 'undefined') ? props.string14 : ''),
string15:((typeof props.string15 !== 'undefined') ? props.string15 : ''),
string16:((typeof props.string16 !== 'undefined') ? props.string16 : ''),
string17:((typeof props.string17 !== 'undefined') ? props.string17 : ''),
string18:((typeof props.string18 !== 'undefined') ? props.string18 : ''),
string19:((typeof props.string19 !== 'undefined') ? props.string19 : ''),
string20:((typeof props.string20 !== 'undefined') ? props.string20 : ''),
string21:((typeof props.string21 !== 'undefined') ? props.string21 : ''),
string22:((typeof props.string22 !== 'undefined') ? props.string22 : ''),
string23:((typeof props.string23 !== 'undefined') ? props.string23 : ''),
string24:((typeof props.string24 !== 'undefined') ? props.string24 : ''),
string25:((typeof props.string25 !== 'undefined') ? props.string25 : ''),
string26:((typeof props.string26 !== 'undefined') ? props.string26 : ''),
string27:((typeof props.string27 !== 'undefined') ? props.string27 : '0'),
// use string27 for item status update
filerepos: [],
defShowBtn: true,
});

const [createRecord, { error, data, loading }] = useMutation(CREATE_MUTATION, {
variables: 
{ input: {
fullname: formState.fullname,
gender: parseInt(formState.gender),
username: formState.username,
frmtype:parseInt(formState.frmtype),
formid:parseInt(formState.formid),
status: parseInt(formState.status),


description: formState.description,
password:formState.password, 
confirmPassword:formState.confirmPassword,
address: formState.address,
city: formState.city,
province: formState.province,
zip: formState.zip,
phone: formState.phone,
title: formState.title,
country: parseInt(formState.country),
tbl: formState.tbl,
rowid: parseInt(formState.rowid),    
total: parseInt(formState.total),    
planid: parseInt(formState.planid), 
itemstatus: parseInt(formState.itemstatus),
id: parseInt(formState.id),
uuid: (sessionStorage.getItem("apiToken") !== 'undefined') ? sessionStorage.getItem("apiToken") : null,

string1:formState.string1,
string2:formState.string2,
string3:formState.string3,
string4:formState.string4 ,
string5:formState.string5,
string6:formState.string6,
string7:formState.string7,
string8:formState.string8,
string9:formState.string9,
string10:formState.string10,
string11:formState.string11,
string12:formState.string12,
string13:formState.string13,
string14:formState.string14,
string15:formState.string15,
string16:formState.string16,
string17:formState.string17,
string18:formState.string18,
string19:formState.string19,
string20:formState.string20,
string21:formState.string21,
string22:formState.string22,
string23:formState.string23,
string24:formState.string24,
string25:formState.string25,
string26:formState.string26,
string27:formState.string27,
}}, fetchPolicy: "no-cache" });

  
useEffect(() => {
if(parseInt(formState.formid) !== parseInt(props.viewid)){
setFormState({ ...formState, formid: props.viewid });
} }, [props.viewid]);


const [passwordShow, setpasswordShow] = useState("password");
function passwordVisibility(e) {   
e.preventDefault();    
if(passwordShow === "password"){setpasswordShow("text"); return;}
setpasswordShow("password"); return;  }

//const prcRecord = async (x) => { createRecord(); }
function handleChangeStatus(){
  setFormState({ ...formState, itemstatus: (parseInt(formState.itemstatus) !== 1 ? 1 : 0) }); }    

function enableField(){
setFormState({ ...formState, planid: (parseInt(formState.planid) !== 1 ? 1 : 0) }); 
} 
function handleChange(e){
  let value = e.target.value;
  if(e.target.type === 'checkbox'){ value = e.target.checked; }
  else if(e.target.type === 'radio'){ 
value = e.target.value;
//let newV = document.getElementById(e.target.id).value;
}
  else if(e.target.type === 'selected'){ 
    value = e.target.selected.value; }  
  const name = e.target.name;
  setFormState({
    ...formState,
    [name]: value
  });
var radios = document.getElementsByName([name]);
for (var i = 0, length = radios.length; i < length; i++) {
  if (radios[i].checked) {
    // do whatever you want with the checked radio
    //alert(radios[i].value);

    // only one radio can be logically checked, don't check the rest
    break;
  }
}
}





function getValue(x){ return formState[x]; }
const {secondaryid} = useParams();
const useId = secondaryid ? '-'+secondaryid : 0;
const inputs = useQuery(GET_LIST, { variables: { listid: parseInt(useId), arg1:'formdisplay' }, fetchPolicy: "cache-first"});
let listInputs = '';
//if (inputs.loading) {return ("Loading... "); }
if (inputs.error) return `Error! ${inputs.error.message}`; 
if (inputs.data) {  
const numbers = inputs.data.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) !== 0; });
listInputs = numbers.map((number) => 
<section className='col-md-6 col-lg-4 mb-4' key={'formcapture'+number.id}>
<Input placeholder={number.title} handleChange={handleChange} value={getValue(number.address)} id={number.address} name={number.address} type={number.city} className="form-control" aria-label={number.title} label={number.title} required={number.itemstatus} />
<section className='helpText'>{number.city} {number.address} {number.title} {number.description}</section>
</section>
);
}

var info = "";
let infoStatus = -1;  
let infoMsg = "";  

if (loading){ info = <div className="alert alert-info d-flex" role="alert">
<span className="spinner-border text-success icon justify-content-center align-self-center"></span>  <span className='mx-3 justify-content-center align-self-center'>Processing, please wait……………</span></div>; }
if (error) {info = <div className="alert alert-danger" role="alert">{error.message}</div>};


if (data) { 
const numbers = data.addRecord;          
infoMsg = numbers.msg; 
infoStatus = numbers.status; 
info = <center>
<section className="spinner-border text-success"></section> 
<section className="iniPage">Loading, please wait ………….</section> 
</center>;	

if(parseInt(infoStatus) === 1){info = <div className="alert alert-success" role="alert">Sign in successful, redirecting</div>;
sessionStorage.setItem("signIn", infoMsg);
sessionStorage.setItem("apiToken", infoMsg);
sessionStorage.setItem("decoded", JSON.stringify(jwt_decode(infoMsg)));
if(document.getElementById('loginFormCon')){document.getElementById('loginFormCon').style.display = 'none';}
window.location.href= "/";}

if(parseInt(infoStatus) === 2){
//if(props.refetchData != 'undefined'){props.refetchData();}
info = <div dangerouslySetInnerHTML={{__html: infoMsg}}  className="alert alert-success" role="alert"></div>;}

if(parseInt(infoStatus) === 0){info = <div dangerouslySetInnerHTML={{__html: infoMsg}}  className="alert alert-danger" role="alert"></div>;}
}



if(props.type === "form"){
switch(parseInt(props.viewid)) { // start current forms
case 56:
case 69:
case 45:
case 22:
case 98:
case 47:
case 44:
case 58:
case 105:
case 24:
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<section className="d-flex align-items-center subTitle pb-2 curraction mb-2">
<section className="">{(props.formmode == 1) ? 'Add' : 'Edit'} Record</section>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center pointer" >{props.viewid != 24 ? <SyncIcon onClick={() => { inputs.refetch()}} /> : null}</div>
</section></section>
<section className='row'>{listInputs}</section>  
<section className='my-3'>

{(inputs.data && inputs.data.getSiteList.length > 0) ? <><span><Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</span>
{(props.id == 'undefined' || props.id == -1) ? null : <span className='pointer mx-3' onClick={() => { props.updateView('list'); props.refetchData();}}>Close</span>}
</> : null}
</section></form>
</div>); 
case -12: // login
return (    
<section className='wrapper'> 
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} className='mb-5'>
<main className="bg-white" id="mainLogInFrm">
<article className="card bg-white">
                     
<section>{info}</section>
<section id='loginFormCon'> 
<section className="card-header text-center py-2 fw-bold text-center">
Login</section>  
<section className='p-4'>
<section className="text-center pt-2">
(Demo Username: info@1and1chat.com , Pass: demo2024 )
</section>    
<section className="card-body p-4 pt-1">
<section className="input-group mb-3">
<span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
<Input name="username" type="email" placeholder="Username" value={formState.username} handleChange={handleChange} required={true} />
</section>
<section className="input-group mb-3">
<span onClick={passwordVisibility} className="input-group-text pointer" id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="password" type={passwordShow} placeholder="Password" value={formState.password} handleChange={handleChange} required={true} />
</section>
<section className="card-title mb-2">
<Input name="continueBtn" className="btn btn-success form-control" type="submit">
Continue
</Input>
</section>
</section>
</section>
</section>  
</article>
</main>
</form>
</section> 
); // end login  
  
case 35: // update item status
return ( 
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); 
 }}>row {formState.rowid} tbl {formState.tbl}
form state {formState.string27} props {props.string27} formid {formState.formid}<span></span>
</form>
); // end login       
//case -9: // add new form field      
//case -5:     
case -105:     
case -58:     
case -56:     
case -44:     
case -69:     
case -45:    
case -22:    
case -47:    
case -98: 
return (        
<div className="p-3">
{info}
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id="frmtype" name="frmtype" />
<Card>
<CardContent>
<section className='row'>
<section className='col-md-6 mb-3'>
<Input placeholder="Title" handleChange={handleChange} value={formState.title} name="title" maxLength="150" type="text" className="form-control" aria-label="Title" required={1} />
</section>
<section className='col-md-6 mb-3'>
<div className='form-floating'>
<select placeholder='Field Type' required className="form-control" id="city" name="city" value={formState.city} onChange={(e) =>
setFormState({
...formState,
[e.target.name]: e.target.value
})} >
<SelectList id={30} />
</select>
<label htmlFor={'city'}>Field Type *</label>
</div>
</section>

<section className='col-md-6 mb-3'>{formState.zip} gg {formState.total}
{(formState.city == 'select') ? 
<GetDataSetList handleChange={handleChange} id={formState.zip} name='zip' placeholder='Dataset' required={true} total={formState.total} value={formState.zip} />
 : null}
</section>

<section className='col-md-6 mb-3'>
{(formState.city == 'select') ? <GetDataFieldList handleChange={handleChange} id={formState.zip} name='total' placeholder='Field' required={true} total={formState.total} value={formState.total} /> : null}
</section>






<section className='col-md-12 mb-3'>
<Input placeholder="Help text" handleChange={handleChange} value={formState.description} name="description" type="text" className="form-control" aria-label="Description" />

</section>

<section className='col-md-6 mb-3'>
<label htmlFor="itemstatus">Required :</label>
<span className='mx-2 switchSize'>
<Switch name='itemstatus' id='itemstatus' onChange={handleChangeStatus} checked={parseInt(formState.itemstatus) === 1 ? true : false}  /></span>
</section>


<section className='col-md-6 mb-3'>
<label htmlFor="itemstatus">Enable :</label>
<span className='mx-2 switchSize'>
<Switch name='planid' id='planid' onChange={enableField} checked={parseInt(formState.planid) === 1 ? true : false}  /></span>
</section>


</section>
</CardContent>
</Card>
<section className='my-3'></section>
<section className='my-3'>
<span>
<Input type="submit" name="save" id='save' className="btn btn-primary btn-sm">Save</Input> 
</span>
<span className='pointer mx-3' onClick={() => { props.updateView('list')}}>Close</span>
</section></form></div>);      
default:     
return null;
}}
return null;
}


function Input(props){
var label = "";
switch(props.type) {
case "submit":
case "button":
return (
<button type={props.type} className={props.className}>{props.children}</button>
);

case "text":
case "email":
case "password":
case "tel":
case "decimal":
case "number":
case "url":
return (<div className='form-floating'>
<input type={(props.type == 'decimal') ? 'number' : props.type} maxLength={props.maxLength !== 'undefined' ? props.maxLength : '100'} placeholder={props.title !== 'undefined' ? props.title : null} onChange={props.handleChange} name={props.name} id={props.name} className={'form-control '+props.className} value={props.value} required={(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? true : false} pattern={(props.pattern !== 'undefined') ? props.pattern : null} onFocus={(props.handleFocus !== 'undefined') ? props.handleFocus : null} min={(props.min !== 'undefined') ? props.min : null} max={(props.max !== 'undefined') ? props.max : null} step={(props.step !== 'undefined') ? props.step : (props.type == 'decimal') ? '0.01' : null} />
<label htmlFor={props.name}>{(props.placeholder !== 'undefined') ? props.placeholder : null} {(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? '*' : null}</label></div>
); 
case "select":
return (
<div className='form-floating'><select placeholder={props.placeholder} required={props.required} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value=''>-- Select --</option>
{props.children}
</select>
<label htmlFor={props.name}>{props.placeholder} *</label>
</div>
);

case "image":
case "file":
return (
<div>
  <label htmlFor={props.name}>{(props.placeholder !== 'undefined' ) ? props.placeholder : 'Select File'}</label>
  <input id={props.name} name={props.name} accept={(props.type == 'image' ) ? "image/*" : '*'} onChange={(e) => {props.handleChange()}} style={{visibility:"hidden"}} multiple={(props.multiple !== 'undefined' ) ? props.multiple : false} type="file" required={(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? true : false} />
</div>
); 

case "textarea":
return (
<div className='form-floating'>
<textarea rows={3} maxLength={props.maxLength !== 'undefined' ? props.maxLength : null} placeholder={props.placeholder !== 'undefined' ? props.placeholder : null} onChange={props.handleChange} name={props.name} id={props.name} aria-label={props.title} className={'form-control '+props.className}  value={props.value} required={(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? true : false} />

<label htmlFor={props.name}>{(props.placeholder !== 'undefined') ? props.placeholder : null} {(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? '*' : null}</label></div>
); 
default:
return null
} 
}


Input.defaultProps = {
   autoComplete:"on",
   encType:"multipart/form-data",
   name:"",
   id:"",
   label:"",
   title:"",
   noValidate:false,
   className:"form-control",
   autoCapitalize:"none",
   ariaLabelledby:null,
   ariaDescribedby:null,
   ariaLabel:null,
   maxLength:null,
   accept:null,
   alt:null,
   autocomplete:null,
   autofocus:null,
   checked:null,
   disabled:null,
   form:null,
   formaction:null,
   formenctype:null,
   formmethod:null,
   formnovalidate:null,
   formtarget:null,
   height:null,
   step:null,
   type:"text",
   src:null,
   size:null, 
   required:false, 
   placeholder:null, 
   readonly:null, 
   pattern:null, 
   multiple:null, 
   minlength:null, 
   list:null, 
   max:null, 
   min:null, 
   value:"", 
   width:null,
   selected:null,
   handleChange:null
};


GetForm.defaultProps = {
  viewid:-1,
  titleid:"",
  returnUrl:"",
  formmode:1,
  type:-1,
  id:0,
  inid:0
  
};
export {Input}