import React, {useState, useEffect } from 'react';
import { Route , Routes, Navigate, useLocation} from 'react-router-dom';
import Home from '../../components/home/home';
import Logo from '../../assets/logo.png';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import H0 from '../../assets/h0.png';
import Divider from '@mui/material/Divider';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import Button from '@mui/material/Button';
import {NotFound} from '../../components/utility/utility';
import Typography from '@mui/material/Typography';
import FImg from '../../assets/content.png';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Link, useParams } from 'react-router-dom';


/*
import H1 from '../../assets/h1.png';
import H2 from '../../assets/h1.png';
import H3 from '../../assets/h1.png';
import H4 from '../../assets/h1.png';
import H5 from '../../assets/h1.png';
import H6 from '../../assets/h1.png';
import H7 from '../../assets/h1.png';
import H8 from '../../assets/h1.png';
import H9 from '../../assets/h1.png';
import H10 from '../../assets/h1.png';
import H11 from '../../assets/h1.png';
import H12 from '../../assets/h1.png';
import H13 from '../../assets/h1.png';
import DrawerLeft from '../../components/drawer/drawer';
import { Link } from 'react-router-dom';









import E1 from '../../assets/c1.jpg';
import E2 from '../../assets/c2.jpg';
import E3 from '../../assets/c3.jpg';

*/


export default function Container(props) {  
return ( 
<section>
<Header />
<Routes> 
<Route exact path="/" element={<Home {...props} />} />
<Route exact path="/help" element={<Help {...props} />} />
<Route exact path="/help/:id/*" element={<DirHelp {...props} />} />
<Route exact path="/dir/:id/*" element={<Dir {...props} />} />
<Route exact path="/con/:id/*" element={<Content {...props} />} />
<Route path="*" element={<NotFound {...props} />} />
</Routes>
<Footer />
</section>
);
}





function Dir(props) {  
let locationItem = useLocation();
return ( 
<section className='defMrg8 mb-4'>
<h4 className='m-3 pageTitleCon fw-bold pb-2'>Media Center &raquo; {locationItem.state.pageTitle}</h4>
<section className="d-flex align-items-start mb-3">
<section className="px-2 sideLink"><CatList sideBar='media' /></section>
<section className="flex-grow-1 p-2 px-5"><ListContent /></section>
</section>
</section>
);}


function Help(props) {  
return ( 
<section className='defMrg8 mb-4'>
<h4 className='m-3 pageTitleCon fw-bold pb-2'>Help Center</h4>
<section className="d-flex align-items-start mb-3">
<section className="px-2 sideLink"><CatList sideBar='help' /></section>
<section className="flex-grow-1 p-2 px-5"><ListContent /></section>
</section>
</section>
);}

function DirHelp(props) {  
let locationItem = useLocation();
return ( 
<section className='defMrg8 mb-4'>
<h4 className='m-3 pageTitleCon fw-bold pb-2'>Help Center &raquo; {locationItem.state.pageTitle}</h4>
<section className="d-flex align-items-start mb-3">
<section className="px-2 sideLink"><CatList sideBar='help' /></section>
<section className="flex-grow-1 p-2 px-5"><ListContent /></section>
</section>
</section>
);}


function CatList(props) {
const [checked, setChecked] = React.useState([0]);
const handleToggle = (value) => () => {
const currentIndex = checked.indexOf(value);
const newChecked = [...checked];

if (currentIndex === -1) {
newChecked.push(value);
} else {
newChecked.splice(currentIndex, 1);
}

setChecked(newChecked);
};


const itemM = ['Events', 'Impact Stories', 'News and Press Release', 'Reports', 'Energy Transition Toolkit','Project Preparation & Investment Facilitation'].map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
		  <ListItem sx={{ paddingBottom:0, marginBottom:2, borderBottom:'solid 1px #E3DFD3' }}
            key={value}
            disablePadding
          >
            <ListItemButton sx={{ padding:0, margin:0, }} role={undefined} onClick={handleToggle(value)} dense>
              <Link onClick={ () => window.topTop() } key={window.uuidv4()} to={{ pathname: `/dir/12/${value}`, }} state={{ itemtitle : value, catTitle:value, pageTitle:value }} className="sideBar">{value}</Link>

            </ListItemButton>
          </ListItem>
        );
      });


const item1 = ['Video Guides', 'Case Studies', 'User Guides', 'Energy Transition Toolkit', 'Knowledgebase','Frequently Asked Questions'].map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
		  <ListItem sx={{ paddingBottom:0, marginBottom:2, borderBottom:'solid 1px #E3DFD3' }}
            key={value}
            disablePadding
          >
            <ListItemButton sx={{ padding:0, margin:0, }} role={undefined} onClick={handleToggle(value)} dense>
              <Link onClick={ () => window.topTop() } key={window.uuidv4()} to={{ pathname: `/help/12/${value}`, }} state={{ itemtitle : value, catTitle:value, pageTitle:value }} className="sideBar">{value}</Link>

            </ListItemButton>
          </ListItem>
        );
      });

const item2 = ['Submit a ticket', 'Chat with us', 'Go to Forum'].map((value) => {
        const labelId2 = `checkbox-list-label-${value}`;
        return (
		  <ListItem sx={{ paddingBottom:0, marginBottom:1, backgroundColor:'#DDE5EA', padding:1, fontWeight:'bold', }}
            key={value}
            disablePadding
          >
            <ListItemButton sx={{ m:0, p:0, padding:0, margin:0, }} role={undefined} dense>
              <ListItemText sx={{ m:0, p:0, padding:0, margin:0, }} id={labelId2} primary={`${value}`} />
            </ListItemButton>
          </ListItem>
        );
      });


return (
    <List sx={{ width: '100%', }}>
   <section>   {props.sideBar && props.sideBar == 'help' ? item1 : itemM} </section>
<Divider sx={{ marginBottom:2, marginTop:2, }} />
 <section className='fw-bold'>	  {props.sideBar && props.sideBar == 'help' ? item2 : null} </section>
    </List>
  );
}

function ListContent() {
const item1 = [`Lorem Ipsum Lorem Ipsum `, `Lorem Ipsum Lorem Ipsum `, `Lorem Ipsum Lorem Ipsum `, `Lorem Ipsum Lorem Ipsum `, `Lorem Ipsum Lorem Ipsum `,`Lorem Ipsum Lorem Ipsum `,`Lorem Ipsum Lorem Ipsum `,`Lorem Ipsum Lorem Ipsum `,`Lorem Ipsum Lorem Ipsum `,`Lorem Ipsum Lorem Ipsum `].map((value, index) => {
return (<section key={`content${index}`}>
<section className='row m-0 p-3 bg-white'>
<section className='col-lg-5 px-2 mb-2'><img src={`/img/H${index}.png`} className="img-fluid" /></section>

<section className='col-lg-7 px-2'>
<section className='fw-bold'>Lorem Ipsum Lorem1</section>
<section className='homeTeaser wrap'>Lorem Ipsum Lorem1 Lorem Ipsum Lorem1 Lorem Ipsum Lorem1 Lorem Ipsum Lorem1 Lorem Ipsum Lorem1</section>
<br />
<Button sx={{ fontSize: 10, fontWeight:'bold',  }} variant="outlined" color="primary" endIcon={<ReadMoreIcon fontSize='10' />} size="small">Read more</Button>
</section>
</section>
<Divider sx={{ marginBottom:4, marginTop:4, }} />
</section>
);
});

return (<section>{item1}</section>);
}

function Content(props) {  
let locationItem = useLocation();
function uuidv4() { return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
return v.toString(16);
});
}return ( 
<section className='defMrg8'>
<section className='m-3 contentTitle fw-bold'><h3>{locationItem.state.pageTitle}</h3></section>





<section className='defMrg8'>
<section className='mb-5 homeTeaser'>
<section className='mb-4'><img src={FImg} className='img-fluid' loading='lazy' /></section>
<section className="daterow d-flex align-items-center p-1 mt-3 mb-3">
<section className="px-2 fw-bold">Aug 21, 2024</section>
<section className="flex-grow-1 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse align-items-center pb-2 pt-2">

</section>
</section>
</section>
<Typography className='mb-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.Dui posuere euismod sem commodo platea eros praesent donec ultrices, rutrum elit fringilla odio blandit porta aenean finibus, cubilia luctus maximus efficitur sapien enim pellentesque integer.Adipiscing cras varius ultrices ornare risus, et purus turpis neque, dignissim feugiat sagittis fringilla.Ultrices ridiculus fames volutpat mattis facilisis interdum libero velit eleifend nullam, sociosqu taciti mauris enim .
</Typography>
<Typography className='mb-3'>
Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.Dui posuere euismod sem commodo platea eros praesent donec ultrices, rutrum elit fringilla odio blandit porta aenean finibus, cubilia luctus maximus efficitur sapien enim pellentesque integer.Adipiscing cras varius ultrices ornare risus, et purus turpis neque, dignissim feugiat sagittis fringilla.Ultrices ridiculus fames volutpat mattis facilisis interdum libero velit eleifend nullam, sociosqu taciti mauris enim .
</Typography><Typography className='mb-3'>
Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.Dui posuere euismod sem commodo platea eros praesent donec ultrices, rutrum elit fringilla odio blandit porta aenean finibus, cubilia luctus maximus efficitur sapien enim pellentesque integer.Adipiscing cras varius ultrices ornare risus, et purus turpis neque, dignissim feugiat sagittis fringilla.Ultrices ridiculus fames volutpat mattis facilisis interdum libero velit eleifend nullam, sociosqu taciti mauris enim .
</Typography><Typography className='mb-3'>
Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.Dui posuere euismod sem commodo platea eros praesent donec ultrices, rutrum elit fringilla odio blandit porta aenean finibus, cubilia luctus maximus efficitur sapien enim pellentesque integer.Adipiscing cras varius ultrices ornare risus, et purus turpis neque, dignissim feugiat sagittis fringilla.Ultrices ridiculus fames volutpat mattis facilisis interdum libero velit eleifend nullam, sociosqu taciti mauris enim .</Typography>
</section>

<section className='mt-3 mb-3'><Button sx={{ fontSize: 10, fontWeight:'bold', marginRight:3  }} variant="outlined" color="primary" endIcon={<FolderOpenIcon fontSize='10' />} size="small">Energy News</Button>
<Button sx={{ fontSize: 10, fontWeight:'bold', marginRight:3  }} variant="outlined" color="primary" endIcon={<FolderOpenIcon fontSize='10' />} size="small">Events</Button>
</section>


</section>

</section> );}
