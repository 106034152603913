import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Logo from '../../assets/logo1.png';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EmailIcon from '@mui/icons-material/Email';
import {SocialConnect} from '../../components/utility/utility';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CookieConsent from "react-cookie-consent";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';


export default function Footer(props) {  //pages.data.pages.nodes
return (
<>

<section className='homeNewsletter p-5' style={{color:'#ffffff', backgroundImage:`url(${process.env.PUBLIC_URL + "/bgnews.jpg"})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
<section className='defMrg8'>

<section className='row'>
<section className='col-md-6 px-5'>
<section className='d-flex flex-wrap mb-4 px-5'>
<section className=''><SubscriptionsIcon /></section>
<section className='flex-grow-1 px-3'>
<section className=""><h4>Stay Updated</h4></section>
<section className="">



<section id=''>
<div className="input-group">
<input type="text" className="form-control" placeholder="Enter your email" aria-label="Newsletter Signup" aria-describedby="basic-addon2" id='yourEmail' />
<div className="input-group-append">
<button className="btn btn-outline-secondary bg-white pointer" type="button"><SendIcon fontSize='10' /> </button>
</div>
</div>
</section>
</section>
</section>
</section>
</section>
<section className='col-md-6 px-3'>
<p className='signUpTeaser px-2'>Stories you might have missed delivered right to your inbox</p>
<p className='signUpTeaser px-2'>Signup now and never miss important information</p>
</section>

</section>
</section>
</section>



<section className='footerCon pt-3'>
<section className='defPad'>
<section className="d-flex">
<section className="footLogoCon d-none d-md-block p-3 px-4 mb-4 mr-3 pr-3 mb-3">
<section className="topLogo mb-3 text-center"><Link to={{ pathname: "/"}}><img src={Logo} className="img-fluid" alt='' title='' loading='lazy' />
</Link>
</section>
<section>
<p>Dredge Resources LLC</p>
<p>
Email: info@xxxx.org<br />
Phone (NG): 080 3742 0000<br />
Phone (Int): +234 81 6703 7067<br />
</p>
<p>
<span className='fw-bold'>Address</span><br />
#1 Ozomagala Street, ODOAKPU,<br />
Onitsha South, Anambra State,
45321,<br /> Nigeria
</p>
</section>
</section>

<section className="flex-fill px-4">
<section className="d-flex justify-items-center align-items-center">
<section className="flex-fill d-none d-md-block px-3 text-center">
<section className="d-flex flex-wrap justify-items-center align-items-center">

<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold', marginRight:'2%' }} variant="contained" color="success" endIcon={<EmailIcon fontSize='10' />}  size="small">Get in touch</Button>
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="secondary" endIcon={<PlaylistAddIcon fontSize='10' />}  size="small">Take a Quiz</Button>
</section>
</section>


<section className="flex-fill px-3">
<section className="catTitle text-center mt-2 mb-1">Connect</section>
<section className="text-center mb-2"><SocialConnect /></section>
</section>
</section>


<section className="mt-3 mb-3 footerTopDiv pt-3 pb-2">
<section className="row my-3">
<section className="col-md-6 col-lg-4 px-5 mb-3">
<section className="fw-bold footHeader mb-3 defText">About</section>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Mission Statement </Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Our Objectives</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Our team</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Vision Statement</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">What we do</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Where we work</Link>
</section>

<section className="col-md-6 col-lg-4 px-5 mb-3">
<section className="fw-bold footHeader mb-3 defText">Media Center </section>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Events </Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Impact Stories</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Energy News</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Publications</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Reports</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Project Preparation &amp; Investment Facilitation</Link>
</section>







<section className="col-md-6 col-lg-4 px-5 mb-3">
<section className="fw-bold footHeader mb-3 defText">Resources</section>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Video Guides</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">ETP Information Helpdesk</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Case Studies</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">User Guides</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Knowledgebase</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Energy Transition Toolkit</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="aboutLinks d-block">Frequently Asked Questions</Link>
</section>
</section>
</section>


</section>
</section>
</section>

<section className="d-flex flex-wrap footerTopDiv mt-1 pt-2 defMrg8">
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Accountability and Transparency</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Cookie Policy</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Privacy Policy</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Sitemap</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Terms of Use</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Disclaimer</Link>
<Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/",}} className="legal">Contact us</Link>
</section>







<section className='d-flex align-items-center footerTopDiv px-5 mt-2 pt-1 flex-wrap'>
<Typography className='copy1 defText mt-2 mb-2'>&copy; {new Date().getFullYear()}. Site Title. All rigths reserved.  <br /><a className='aboutLinks mx-2' href='/' target='_blank'>Admin Login</a> <a className='aboutLinks' href='/' target='_blank'>Webmail</a></Typography>

<section className="flex-grow-1">
<section className="d-flex flex-row-reverse align-items-center">
<Typography className="copy1 mt-2 mb-2">Designed By <a rel="noopener noreferrer" className='aboutLinks' href='https://dredge.com.ng/' target='_blank'>Dredge Resources </a> </Typography>
</section>
</section>
</section>
</section>


<div className="scrollUp" onClick={ () => window.topTop() }>
<Tooltip title="Back to top" arrow={true} >
<IconButton><ArrowDropUpIcon sx={{ fontSize: 30, color: '#ffffff', fontWeight:'bold', }} />
</IconButton></Tooltip>
</div>
<span className='chatIcon p-2'><QuestionAnswerIcon sx={{ fontSize: 30, color: '#ffffff', fontWeight:'bold', }} /></span>
<CookieConsent
  location="bottom"
  buttonText="Sure man!!"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={-1}
>
  This website uses cookies to enhance the user experience.
</CookieConsent>
</>
);}
