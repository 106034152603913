import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import {SocialConnect, Tour} from '../../components/utility/utility';
//import SearchIcon from '@mui/icons-material/Search';
//import { styled } from '@mui/material/styles';
//import Box from '@mui/material/Box';
//import Drawer from '@mui/material/Drawer';
//import CssBaseline from '@mui/material/CssBaseline';
//import MuiAppBar from '@mui/material/AppBar';
//import IconButton from '@mui/material/IconButton';
//import MenuIcon from '@mui/icons-material/Menu';
////import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import LockIcon from '@mui/icons-material/Lock';
//import WorkIcon from '@mui/icons-material/Work';


//import Avatar from '@mui/material/Avatar';
//import Menu from '@mui/material/Menu';
//import MenuItem from '@mui/material/MenuItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import Divider from '@mui/material/Divider';
//import Typography from '@mui/material/Typography';
//import Tooltip from '@mui/material/Tooltip';
//import PersonAdd from '@mui/icons-material/PersonAdd';
//import Settings from '@mui/icons-material/Settings';
//import Logout from '@mui/icons-material/Logout';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from '@mui/icons-material/Article';
import SearchIcon from '@mui/icons-material/Search';




import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';


import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';



import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import BoxJ from '@mui/joy/Box';
import DrawerJ from '@mui/joy/Drawer';
import ButtonJ from '@mui/joy/Button';
import ListJ from '@mui/joy/List';
import DividerJ from '@mui/joy/Divider';
import ListItemJ from '@mui/joy/ListItem';
import ListItemButtonJ from '@mui/joy/ListItemButton';


export default function Header(props) { 
let { primaryid } = useParams();
const optionsAbout = [
  {title:'Mission Statement',id:12},
  {title:'Our Objectives',id:12},
  {title:'Our team',id:12},
  {title:'Vision Statement',id:12},
  {title:'What we do',id:12},
  {title:'Where we work',id:12},
];
const optionsHelp = [
  {title:'Video Guides',id:12},
  {title:'Case Studies',id:12},
  {title:'User Guides',id:12},
  {title:'Knowledgebase',id:12},
  {title:'Frequently Asked Questions',id:12},
 {title:'Submit a ticket',id:12},
 {title:'Chat with us',id:12},
];
const optionsMedia = [
  {title:'Events',id:12},
  {title:'Impact Stories',id:12},
  {title:'News and Press Release',id:12},
  {title:'Reports',id:12},
  {title:'Energy Transition Toolkit',id:12},
  {title:'Project Preparation & Investment Facilitation',id:12},
];






return (
<>  
<section className="topMenuRow ">
<section className="d-flex align-items-center pt-2 p-2">
<span className='mx-2'><LocalPhoneIcon sx={{ fontSize: 20, }} />+234 801 20000000</span>
<span className='d-none d-lg-block'>
<span className='mx-2 contactTop p-1 px-4'><EmailIcon sx={{ fontSize: 20, }} />
<Button variant="text" href="mailto:dredgeresources@gmail.com" sx={{ fontSize: 10, color: '#ffffff', fontWeight:'bold',  }}>Get in touch</Button>
</span>


<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="secondary" endIcon={<PlaylistAddIcon fontSize='10' />}  size="small">Take a Quiz</Button>
</span>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center socialsection">
<SocialConnect /><span className='mx-2 px-3 newsletter d-none d-lg-block'>
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" endIcon={<SendIcon fontSize='10' />} size="small">Newsletter</Button></span>
</div>
</section>
</section>
</section>
<Tour />

<section className="defPad sticky-top pt-3 pb-3 d-block d-lg-none topMenuRow2">
<section className="d-flex">
<section className='logoTopCon1'><Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/"}}><img src={Logo} className="img-fluid" /></Link></section>
<section className="flex-grow-1 d-flex flex-column">
<section className="topMenu1 mb-2 flex-fill d-flex flex-row-reverse align-items-center">
<ResponsiveDrawer />
</section>
</section>
</section>
</section>





<section className="defPad sticky-top pt-4 pb-4 d-none d-lg-block topMenuRow2">
<section className="d-flex align-items-center">
<section className='logoTopCon' ><Link onClick={ () => window.topTop() } to={{ pathname: "/"}}><img src={Logo} key={window.uuidv4()} className="img-fluid" /></Link></section>

<section className="flex-grow-1 d-flex flex-column">
<section className="topMenu1 mb-2 flex-fill d-flex flex-row-reverse">
<span className=''><SearchIcon /></span>
<span className='mx-4'><SimpleListMenu catTitle='Help Center' drop={false} linkType='help' title='Need Help' linkAdd='/help' /></span>
<span className=''><SimpleListMenu catTitle='Media Center' title='Media center' column2={true} drop={true} options={optionsMedia}  options2={optionsHelp} linkType='dir' /></span>
<span className='mx-4'><SimpleListMenu drop={true} catTitle='About us' title='About' options={optionsAbout} /></span>
<span className=''><SimpleListMenu drop={false} title='Home' linkAdd='/' /></span>
</section>
</section>
</section>
<Divider sx={{ marginTop:2, }}
 />
</section>





</>
);}


function SimpleListMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    //setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
//function scrollTop(){ window.scrollTo({ top: 0, left: 0, behavior: "smooth", }); }

let drop = props.drop ? true : false;
let options = props.options ? props.options : [];
let options2 = props.options2 ? props.options2 : [];
let linkAdd = props.linkAdd ? <Link key={window.uuidv4()} onClick={ () => window.topTop() } to={{ pathname: `/${props.linkAdd}`, }}  state={{ itemtitle : props.title, catTitle:props.catTitle, }} className="topMenu">{props.title}</Link> : false;

let itemList = options.map((option, index) => (
<MenuItem
key={window.uuidv4()}
onClick={(event) => handleMenuItemClick(event, index)}
><Link onClick={ () => window.topTop() } key={window.uuidv4()} to={{ pathname: `/${props.linkType ? props.linkType : 'con'}/${option.id}/${option.title}`, }} state={{ itemtitle : option.title, catTitle:props.catTitle, pageTitle:option.title }} className="topMenu">{option.title}</Link>
</MenuItem>
));


let itemList2 = options2.map((option, index) => (
<MenuItem
key={window.uuidv4()}
onClick={(event) => handleMenuItemClick(event, index)}
><Link onClick={ () => window.topTop() } key={window.uuidv4()} to={{ pathname: `/help/${option.id}/${option.title}`, }} state={{ itemtitle : option.title, catTitle:props.catTitle, pageTitle:option.title }} className="topMenu">{option.title}</Link>
</MenuItem>
));;

let itemCol = props.column2 ? <Menu         
id="lock-menu2"
anchorEl={anchorEl}
open={open}
onClose={handleClose}
MenuListProps={{
'aria-labelledby': 'lock-button',
role: 'listbox',
}}
>
<section className='d-flex flex-wrap'> 
<section className='flex-fill p-2 topMenuCol wrap'>{itemList}</section> 
<section className='flex-fill p-2 topMenuCol wrap'>{itemList2}</section>
</section>
</Menu>
 : <Menu         
id="lock-menu"
anchorEl={anchorEl}
open={open}
onClose={handleClose}
MenuListProps={{
'aria-labelledby': 'lock-button',
role: 'listbox',
}}
>
{itemList}
</Menu>;



  return (
<>
{props.linkAdd ? <Link key={window.uuidv4()} to={`${props.linkAdd}`} state={{ itemtitle : props.title, catTitle:props.catTitle, }} onClick={ () => window.topTop()} className="topMenu">{props.title}</Link> : 

<div>
<List
component="nav"
aria-label={props.title}
sx={{ bgcolor: 'background.paper', margin:0, padding:0, }}
>
<ListItemButton
sx={{ bgcolor: 'background.paper', margin:0, padding:0, }}

id="lock-button"
aria-haspopup="listbox"
aria-controls="lock-menu"
aria-label={props.title}
aria-expanded={open ? 'true' : undefined}
onClick={handleClickListItem}
>
<ListItemText
sx={{ bgcolor: 'background.paper', margin:0, padding:0, color:'#858585', fontFamily:'Helvetica', }}
primary=<ListMenuTitle drop={drop} title={props.title} />
/>
</ListItemButton>
</List>
{itemCol}
</div>
}</>
);
}
function ListMenuTitle(props) {
let drop = props.drop ? <KeyboardArrowDownIcon /> : null;
  return ( <span className=''>{props.title} {drop} </span> ); }
  
  


function ResponsiveDrawer(props) {
const [open, setOpen] = React.useState(false);
const toggleDrawer = (inOpen) => (event) => {
if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
return;}

setOpen(inOpen);
};
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


return (  <BoxJ sx={{ display: 'flex' }}>
<span className='pointer' onClick={toggleDrawer(true)}><MenuIcon /></span>
<DrawerJ open={open} onClose={toggleDrawer(false)}>
<DrawerHeader>
<IconButton onClick={toggleDrawer(false)}>
<ChevronLeftIcon />
</IconButton>
</DrawerHeader>	
<Divider />
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}><Link to={{pathname: "/",}} key={window.uuidv4()} onClick={ () => window.topTop() } className="">Home</Link></section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>




<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={toggleDrawer(false)}>Menu Item List</section>
<Divider />
</DrawerJ>
</BoxJ>);
}
