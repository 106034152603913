import React, { useState, useRef, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import NFImg from '../../assets/notfound.png';
import { Link} from 'react-router-dom';


//import * as d3 from 'd3';
import { useLocation } from 'react-router-dom';
//import CardContent from '@mui/material/CardContent';

import { styled } from '@mui/system';


import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
//import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';



import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
//import BoxJ from '@mui/joy/Box';
//import Button from '@mui/joy/Button';
//import Card from '@mui/joy/Card';
//import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
//import Typography from '@mui/joy/Typography';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { ScatterChart } from '@mui/x-charts/ScatterChart';
import { ScatterPlot } from '@mui/x-charts/ScatterChart';

import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';



//import Button from '@mui/joy/Button';
import Form, {Input} from '../../components/form/form';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import { DataGrid } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
//import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';



import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import Settings from '@mui/icons-material/Settings';
import ListSharp from '@mui/icons-material/ListSharp';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Sheet from '@mui/joy/Sheet';
//import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DeleteIcon from '@mui/icons-material/Delete';
//import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Add from '@mui/icons-material/Add';

//import ButtonGroup from '@mui/joy/ButtonGroup';
//import IconButton from '@mui/joy/IconButton';
//import Tooltip from '@mui/joy/Tooltip';
//import Person from '@mui/icons-material/Person';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Helmet} from "react-helmet";
//import { PAGEDETAILS, POSTDETAILS } from '../../components/gpl/gpl';

//import { styled } from '@mui/material/styles';
//import VisibilityIcon from '@mui/icons-material/Visibility';
//import CloseIcon from '@mui/icons-material/Close';




//import Button from '@mui/material/Button';
//import Dialog from '@mui/material/Dialog';
//import ListItemText from '@mui/material/ListItemText';
//import ListItemButton from '@mui/material/ListItemButton';
//import List from '@mui/material/List';
//import Divider from '@mui/material/Divider';
//import AppBar from '@mui/material/AppBar';
//import Toolbar from '@mui/material/Toolbar';
//import IconButton from '@mui/material/IconButton';
//import Typography from '@mui/material/Typography';
//import CloseIcon from '@mui/icons-material/Close';
//import Slide from '@mui/material/Slide';


//import CircularProgress from '@mui/material/CircularProgress';
//import Box from '@mui/material/Box';




//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';

//import ListSubheader from '@mui/material/ListSubheader';
//import Switch from '@mui/material/Switch';
//import WifiIcon from '@mui/icons-material/Wifi';
//import BluetoothIcon from '@mui/icons-material/Bluetooth';



import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';



function CardCon(props) { 
return (
<>
<section><img src={props.IMG} className='img-fluid' loading="lazy" alt="" /></section>
<Typography level="title-md text-center fw-bold mb-2">
<h3 className='bg-info text-white pt-2 p-3'>{props.title} <br />
<span className='display-2'><Counter value={props.value} id={props.id} /></span>
</h3>
</Typography>
</>
);}

function NotFound(props) {  
return ( 
<section className='defMrg8'>
<section className='defMrg8 m-5 p-3'>
<section className='mt-5 mb-5 notfound pt-5 pb-5'>
<section className='d-block d-lg-none p-5 text-center'><img src={NFImg} className='img-fluid' /></section>


<section className="d-flex align-items-center pt-2 p-2">
<section className=' d-flex flex-column mx-2 p-1 px-4 p-3'>
<h3>Something&apos; s wrong here ...</h3>
<p>We can&apos;t find the page you&apos;re looking for. Check out our Help Center or head back home</p>
<p>
<span className='m-1 mt-1 mb-1'><Button sx={{ fontSize: 11, m:1, backgroundColor:'#104909', color: '#000000', fontWeight:'bold',  }} variant="contained" color="primary" size="small"><Link onClick={ () => window.topTop() } to={{ pathname: "/help"}} state={{ itemtitle : 'Page not found', pageTitle:'Page not found' }} className='btnLinkWhite'>Help Center</Link></Button></span>
<span className='m-1 mt-1 mb-1'>
<Button sx={{ fontSize: 11, m:1, color: '#000000', backgroundColor:'#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" size="small"><Link onClick={ () => window.topTop() } to={{ pathname: "/"}} className='btnLinkBlack'>Home</Link></Button></span>
</p>
</section>


<section className="flex-grow-1 d-flex flex-column d-none d-lg-block justify-content-start align-items-start align-self-start">
<img src={NFImg} className='img-fluid' />
</section>
</section>
</section>
</section> 
</section> 
);
}


function SocialConnect(props) { 
return (<>
<Tooltip title="Face book" arrow={true}><span className='mx-2 pointer'><FacebookIcon sx={{ fontSize: 20, }} /></span></Tooltip>
<Tooltip title="LinkedIn" arrow={true}><span className='mx-2 pointer'><LinkedInIcon sx={{ fontSize: 20, }} /></span></Tooltip>
<Tooltip title="X" arrow={true}><span className='mx-2 pointer'><XIcon sx={{ fontSize: 20, }} /></span></Tooltip>
<Tooltip title="Pinterest" arrow={true}><span className='mx-2 pointer'><PinterestIcon sx={{ fontSize: 20, }} /></span></Tooltip>
<Tooltip title="YouTube" arrow={true}><span className='mx-2 pointer'><YouTubeIcon sx={{ fontSize: 20, }} /></span></Tooltip>

</>);}
function Tour(props) { 
const [formState, setFormState] = useState({
display: true,
});
function closeTour(){
setFormState({
...formState,
display: false,
})};
return (<>
{formState.display == true ? <section className="fixedTop p-3">
<section className="fw-bold mb-3">New Visitor</section>
<section className="mb-4">Quickly find what you want, Take a guided tour of our website</section>
<section className="">
<Stack spacing={2} direction="row">
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="success" size="small">Start Tour</Button><Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="warning" size="small" onClick={() => { closeTour()}}>Close</Button>

</Stack>
</section>
</section> : null}
</>
);}


function Counter(props) { 
const [startTime, setStartTime] = useState(null);
const [now, setNow] = useState(null);
const intervalRef = useRef(null);
const ref = useRef(0);
const refbox = useRef(null);

function handleStart() {
setStartTime(0);
if(parseInt(ref.current) < parseInt(props.value)){setNow(ref.current+1);}

clearInterval(intervalRef.current);
intervalRef.current = setInterval(() => {
if(parseInt(ref.current) < parseInt(props.value)){
ref.current=ref.current+1;
setNow(ref.current);
}

if(parseInt(ref.current) > parseInt(props.value)){
clearInterval(intervalRef.current);
document.removeEventListener('scroll', handleStop, { passive: true });
}
}, 10); }

function handleStop() { clearInterval(intervalRef.current); }
let secondsPassed = 0;
if (startTime != null && now != null) { secondsPassed = (now - startTime); }



function isInViewport(box) { 
const rect = box.getBoundingClientRect();
let result = rect.top>= 0 &&
rect.left>= 0 &&
rect.bottom<= (window.innerHeight || document.documentElement.clientHeight) &&
rect.right<= (window.innerWidth || document.documentElement.clientWidth);
return result;
}

useEffect(() => {
refbox.current = document.querySelector('#'+props.id);
if(isInViewport(refbox.current)){handleStart();}
document.addEventListener('scroll', function () {
if(isInViewport(refbox.current)){handleStart();}
}, { passive: true });
return () => {
document.removeEventListener('scroll', handleStop, { passive: true }); 
}; }, []);
return ( <span className='p-2 text-center' id={props.id}>{secondsPassed}</span> );}



function BasicBar(props) {
const [type, setType] = React.useState('bar');

return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
      series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
      height={300}
    />
  );
}

function BasicLineChart() {
  return (
    <LineChart
      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      series={[
        {
          data: [2, 5.5, 2, 8.5, 1.5, 5],
        },
      ]}
      height={300}
    />
  );
}



function BasicScatter() {
const data = [
  {
    id: 'data-0',
    x1: 329.39,
    x2: 391.29,
    y1: 443.28,
    y2: 153.9,
  },
  {
    id: 'data-1',
    x1: 96.94,
    x2: 139.6,
    y1: 110.5,
    y2: 217.8,
  },
  {
    id: 'data-2',
    x1: 336.35,
    x2: 282.34,
    y1: 175.23,
    y2: 286.32,
  },
  {
    id: 'data-3',
    x1: 159.44,
    x2: 384.85,
    y1: 195.97,
    y2: 325.12,
  },
  {
    id: 'data-4',
    x1: 188.86,
    x2: 182.27,
    y1: 351.77,
    y2: 144.58,
  },
  {
    id: 'data-5',
    x1: 143.86,
    x2: 360.22,
    y1: 43.253,
    y2: 146.51,
  },
  {
    id: 'data-6',
    x1: 202.02,
    x2: 209.5,
    y1: 376.34,
    y2: 309.69,
  },
  {
    id: 'data-7',
    x1: 384.41,
    x2: 258.93,
    y1: 31.514,
    y2: 236.38,
  },
  {
    id: 'data-8',
    x1: 256.76,
    x2: 70.571,
    y1: 231.31,
    y2: 440.72,
  },
  {
    id: 'data-9',
    x1: 143.79,
    x2: 419.02,
    y1: 108.04,
    y2: 20.29,
  },
  {
    id: 'data-10',
    x1: 103.48,
    x2: 15.886,
    y1: 321.77,
    y2: 484.17,
  },
  {
    id: 'data-11',
    x1: 272.39,
    x2: 189.03,
    y1: 120.18,
    y2: 54.962,
  },
  {
    id: 'data-12',
    x1: 23.57,
    x2: 456.4,
    y1: 366.2,
    y2: 418.5,
  },
  {
    id: 'data-13',
    x1: 219.73,
    x2: 235.96,
    y1: 451.45,
    y2: 181.32,
  },
  {
    id: 'data-14',
    x1: 54.99,
    x2: 434.5,
    y1: 294.8,
    y2: 440.9,
  },
  {
    id: 'data-15',
    x1: 134.13,
    x2: 383.8,
    y1: 121.83,
    y2: 273.52,
  },
  {
    id: 'data-16',
    x1: 12.7,
    x2: 270.8,
    y1: 287.7,
    y2: 346.7,
  },
  {
    id: 'data-17',
    x1: 176.51,
    x2: 119.17,
    y1: 134.06,
    y2: 74.528,
  },
  {
    id: 'data-18',
    x1: 65.05,
    x2: 78.93,
    y1: 104.5,
    y2: 150.9,
  },
  {
    id: 'data-19',
    x1: 162.25,
    x2: 63.707,
    y1: 413.07,
    y2: 26.483,
  },
  {
    id: 'data-20',
    x1: 68.88,
    x2: 150.8,
    y1: 74.68,
    y2: 333.2,
  },
  {
    id: 'data-21',
    x1: 95.29,
    x2: 329.1,
    y1: 360.6,
    y2: 422.0,
  },
  {
    id: 'data-22',
    x1: 390.62,
    x2: 10.01,
    y1: 330.72,
    y2: 488.06,
  },
];
  return (
		  
		   <Box sx={{ width: '100%' }}>
     

      <div>
         <ScatterChart
      height={300}
      series={[
        {
          label: 'Series A',
          data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
        },
        {
          label: 'Series B',
          data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
        },
      ]}
    />
      </div>
	  
	
	
	
     
	
	
	
	
    </Box>



   
  );
}



export {SocialConnect, CardCon, NotFound, Tour, BasicBar, BasicLineChart, BasicScatter}
//export { BasicBar, BasicComposition, BasicLineChart, SwitchSeriesType, Counter, CardCon, AddToAny, BasicScatter}