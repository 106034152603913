import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import Container from './components/container/container';
//import jwt_decode from "jwt-decode";



var chatServer = ""; 
//var chatServer = "http://app.dredge.com.ng:433/graphql";
var token = "none";
var apiToken = "none";
//var decoded = "";
if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");
//decoded = jwt_decode(token);
//console.log('decoded');
//console.log(decoded);
//console.log('decoded');
} 
console.log('token')
console.log(token)



const httpLink = createHttpLink({ uri: chatServer,});
const authLink = setContext((_, { headers }) => {
return { headers: { ...headers, authorization: token ? `Bearer ${token} ${apiToken}` : "", } } });
const client = new ApolloClient({ link: authLink.concat(httpLink), cache: new InMemoryCache(), connectToDevTools: true })


function App() { 
return (
<BrowserRouter>
<ApolloProvider client={client}>
<Routes>
<Route path="*" element={<Container /> } />
</Routes>
</ApolloProvider>
</BrowserRouter>);
}
export default App;