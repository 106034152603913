import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
//import {useQuery} from '@apollo/client';
import Logo from '../../assets/logo.png';
import C1 from '../../assets/c1.jpg';
import C2 from '../../assets/c2.jpg';
import C3 from '../../assets/c3.jpg';
import RL from '../../assets/rl.png';
import RV from '../../assets/rv.png';
import RT from '../../assets/rt.png';
import Sample from '../../assets/sample.png';
import Mission from '../../assets/mission.png';
import E1 from '../../assets/e1.png';
import E2 from '../../assets/e2.png';
import E3 from '../../assets/e3.png';
import E4 from '../../assets/e4.png';
import J1 from '../../assets/j1.png';
import CC1 from '../../assets/c1.png';
import P1 from '../../assets/p1.png';
import Carousel from 'react-bootstrap/Carousel';
import Button from '@mui/material/Button';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {CardCon, BasicBar, BasicLineChart, BasicScatter} from '../../components/utility/utility';
//import {CardCon, SwitchSeriesType, BasicComposition, } from '../../components/utility/utility';
//import { Chart } from "react-google-charts";
//import Typography from '@mui/material/Typography';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import SendIcon from '@mui/icons-material/Send';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
//import Box from '@mui/material/Box';
//import InputLabel from '@mui/material/InputLabel';
//import FormControl from '@mui/material/FormControl';
//import NativeSelect from '@mui/material/NativeSelect';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//import EditIcon from '@mui/icons-material/Edit';
//import Divider from '@mui/material/Divider';
//import ArchiveIcon from '@mui/icons-material/Archive';
//import FileCopyIcon from '@mui/icons-material/FileCopy';
//import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// start options
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



import { BarChart } from '@mui/x-charts/BarChart';


export default function Home(props) { 
return (
<>
<section className='homeBanner p-5' style={{color:'#ffffff', backgroundImage:`url(${process.env.PUBLIC_URL + "/banner.png"})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
<section className='homeCoverStory d-flex p-5'>
<Carousel>

<Carousel.Item>
<section className="row">
<section className="col-md mb-6 m-0 px-5"><img src={C1} className='hmImg' loading='lazy' /></section>
<section className="col-md mb-6 m-0 p-3 px-5">
<h2>Lorem Ipsum Lorem</h2>
<section>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</section>
</section>
</section>
</Carousel.Item>


<Carousel.Item>
<section className="row">
<section className="col-md mb-6 m-0 px-5"><img src={C2} className='hmImg' loading='lazy' /></section>
<section className="col-md mb-6 m-0 p-3 px-5">
<h2>Lorem Ipsum Lorem</h2>
<section>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</section>
</section>
</section>
</Carousel.Item>


<Carousel.Item>
<section className="row">
<section className="col-md mb-6 m-0 px-5"><img src={C3} className='hmImg' loading='lazy' /></section>
<section className="col-md mb-6 m-0 p-3 px-5">
<h2>Lorem Ipsum Lorem</h2>
<section>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</section>
</section>
</section>
</Carousel.Item>
</Carousel> 
</section>


<section className='defMrg8 mt-5'>


<section className='row'>
<section className='col-md-4 col-lg-3 px-4 mb-4 mt-3'>
<section className='homeIntro p-3'> 
<section className='introHeaader mt-2 mb-2'>Resources</section>
<section className='introTeaser'> When using Lorem Ipsum for creating dummy content for your newly created website ...</section>
</section>
</section>
<section className='col-md-4 col-lg-3 px-4 mb-4 mt-3'>
<section className='homeIntro p-3'> 
<section className='introHeaader mt-2 mb-2'>Latest News</section>
<section className='introTeaser'> When using Lorem Ipsum for creating dummy content for your newly created website ...</section>
</section>
</section>

<section className='col-md-4 col-lg-3 px-4 mb-4 mt-3'>
<section className='homeIntro p-3'> 
<section className='introHeaader mt-2 mb-2'>Reports</section>
<section className='introTeaser'> When using Lorem Ipsum for creating dummy content for your newly created website ...</section>
</section>
</section>

<section className='col-md-4 col-lg-3 px-4 mb-4 mt-3 d-none d-lg-block'>
<section className='homeIntro p-3'> 
<section className='introHeaader mt-2 mb-2'>ETP Information Helpdesk</section>
<section className='introTeaser'> When using Lorem Ipsum for creating dummy content for your newly created website ...</section>
</section>
</section>
</section>
</section>
</section>

<section style={{color:'#000000', backgroundImage:`url(${process.env.PUBLIC_URL + "/map.png"})`, backgroundRepeat: 'no-repeat', minHeight:'400px', backgroundAttachment: 'fixed', backgroundPosition: 'center',}} className='text-center p-4'>
<section className="p-2"><img src={Mission} className='missionImg' /></section>
<section className='mb-5'>Our Mission</section>
<section className='mt-2 mb-5 defMrg8'><h2 className='px-5'>Nigeria&acute;s Energy Transition Plan defines a pathway for achieving the country&acute;s net zero by 2060 ambitions, with interventions cutting across five sectors including Power, Transport, Industry, Buildings, and Oil & Gas</h2></section>
<section><Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="success" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">More about us</Button></section>
</section>





<section className="defMrg8 mt-5">
<section className="d-flex mt-5 dataHeader pb-2 mb-3">
<section className="px-2"><h4 className="fw-bold">National Energy Data</h4></section>
<section className="flex-grow-1 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse align-items-center fw-bold">
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" endIcon={<RemoveRedEyeIcon fontSize='10' />}  size="small">View All Data</Button>
</section>
</section>
</section>
<section>
<section className='row mb-5 mt-5'>
<section className='col-lg-4 px-1'>
<BasicBar />
</section>
<section className='col-lg-4 px-1 mt-3'>
<BasicLineChart />
 
</section>
<section className='col-lg-4 px-1 mt-3'>
<BasicScatter />
</section>	
</section>
</section>


<section className="d-flex mt-5 dataHeader pb-2 mb-3">
<section className="px-2"><h4 className="fw-bold">Latest Articles</h4></section>
<section className="flex-grow-1 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse align-items-center fw-bold">
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" endIcon={<RemoveRedEyeIcon fontSize='10' />}  size="small">View All</Button>
</section>
</section>
</section>

<section className='row mb-5'>
<section className='col-lg-6 px-2'>
<img src={Sample} className='img-fluid' loading='lazy' />
</section>

<section className='col-lg-6 px-2'>
<h3 className='mt-3'>Lorem Ipsum Lore</h3>
<section className='mt-2 mb-2'>
<Button sx={{ fontSize: 10, fontWeight:'bold',  }} variant="outlined" color="primary" endIcon={<FolderOpenIcon fontSize='10' />} size="small">Energy News</Button>
</section>
<section className=''>
<p className='homeTeaser'>Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.Dui posuere euismod sem commodo platea eros praesent donec ultrices, rutrum elit fringilla odio blandit porta aenean finibus, cubilia luctus maximus efficitur sapien enim pellentesque integer.Adipiscing cras varius ultrices ornare risus, et purus turpis neque, dignissim feugiat sagittis fringilla.Ultrices ridiculus fames volutpat mattis facilisis interdum libero velit eleifend nullam, sociosqu taciti mauris enim .</p>
</section>
</section>
</section>


<section className='row mb-5 mt-5'>
<section className='col-lg-6 px-2'>
<h3 className='mt-3'>Lorem Ipsum Lore</h3>
<section className='mt-2 mb-2'>
<Button sx={{ fontSize: 10, fontWeight:'bold',  }} variant="outlined" color="primary" endIcon={<FolderOpenIcon fontSize='10' />} size="small">Events</Button>
</section>
<section className=''>
<p className='homeTeaser'>Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.Dui posuere euismod sem commodo platea eros praesent donec ultrices, rutrum elit fringilla odio blandit porta aenean finibus, cubilia luctus maximus efficitur sapien enim pellentesque integer.Adipiscing cras varius ultrices ornare risus, et purus turpis neque, dignissim feugiat sagittis fringilla.Ultrices ridiculus fames volutpat mattis facilisis interdum libero velit eleifend nullam, sociosqu taciti mauris enim .</p>
</section>
</section>
<section className='col-lg-6 px-2'>
<img src={Sample} className='img-fluid' loading='lazy' />
</section>
</section>


</section>





<section className="diveIn defPad text-center">
<section className="p-5">
<section className="px-2 dineInSecHeader"><h1 id='diveIn' className="fw-bold">Dive <span>In <MoveDownIcon /></span></h1></section>


<section className="d-flex my-3 flex-wrap">
<section className="flex-fill px-2 mb-3 col-md-4">
<section className="p-5">
<section className=""><img src={RL} className='img-fluid' loading='lazy' /></section>
<h2 className="dineInTitle my-4">Resource Hub</h2>
<section className="my-2">Nigeria Energy Transition Plan defines a pathway for achieving the country net zero by 2060 ambitions, with interventions </section>
<section className="mt-5"><Button sx={{ paddingLeft:'25px', paddingRight:'25px', fontWeight:'bold',  }} variant="contained" color="success">Learn more</Button>
</section>
</section>
</section>

<section className="flex-fill px-2 mb-3 col-md-4">
<section className="p-5">
<section className=""><img src={RV} className='img-fluid' loading='lazy' /></section>
<h2 className="dineInTitle my-4">Online Community</h2>
<section className="my-2">Nigeria Energy Transition Plan defines a pathway for achieving the country net zero by 2060 ambitions, with interventions </section>
<section className="mt-5"><Button sx={{ paddingLeft:'25px', paddingRight:'25px', fontWeight:'bold',  }} variant="contained" color="success">Learn more</Button>
</section>
</section>
</section>

<section className="flex-fill px-2 mb-3 col-md-4 d-none d-lg-block">
<section className="p-5">
<section className=""><img src={RT} className='img-fluid' loading='lazy' /></section>
<h2 className="dineInTitle my-4">Live Events</h2>
<section className="my-2">Nigeria Energy Transition Plan defines a pathway for achieving the country net zero by 2060 ambitions, with interventions </section>
<section className="mt-5"><Button sx={{ paddingLeft:'25px', paddingRight:'25px', fontWeight:'bold',  }} variant="contained" color="success">Learn more</Button>
</section>
</section>
</section>
</section>

</section>
</section>


<section className="mt-5 mb-3 defMrg8">
<section className="d-flex dataHeader pb-2">
<section className="px-2"><h4 className="fw-bold">Trending Videos</h4></section>
<section className="flex-grow-1 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse align-items-center fw-bold">
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" endIcon={<RemoveRedEyeIcon fontSize='10' />}  size="small">View All Video</Button>
</section>
</section>
</section>

<section className="row mt-3">
<section className="col-sm-12 col-md-4 px-2 mb-5">
<div className="videoCon">
  <iframe className="responsive-iframe" src="https://www.youtube.com/embed/5Eo_ibRvK0M"></iframe>
</div>
</section>

<section className="col-sm-12 col-md-4 px-2 mb-5">
<div className="videoCon">
  <iframe className="responsive-iframe" src="https://www.youtube.com/embed/5Eo_ibRvK0M"></iframe>
</div>
</section>

<section className="col-sm-12 col-md-4 px-2 mb-5">
<div className="videoCon">
  <iframe className="responsive-iframe" src="https://www.youtube.com/embed/5Eo_ibRvK0M"></iframe>
</div>
</section>
</section>
</section>





<section className='diveIn defPad8 p-5'>
<section className="pt-5 mt-2 px-2 text-center"><h2 className="fw-bold">Energy Initiatives, Events and Policy Developments</h2></section>


<section className="d-flex flex-wrap p-5">
<section className="flex-fill px-4 mb-3 col-md-4 d-none d-lg-block">




<div className="container_row text-center">
<div className="layer1"><img src={E1} className='img-fluid' loading='lazy' /></div>
<div className="layer2">
<section className='bg-white p-4 m-4'>
<section className='mb-5'>
<h3 className='mb-3'>Lorem Ipsum Lore</h3>
<section className='mb-3 homeTeaser'>
	Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.
	</section>
</section>
<section className='text-center'><Button sx={{ fontWeight:'bold',  }} variant="contained" color="success" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">Read more</Button>
</section>
</section>

</div>
</div>
<div className="container_row">
</div>
</section>


<section className="flex-fill px-4 mb-3 col-md-4">
<div className="container_row text-center">
<div className="layer1"><img src={E3} className='img-fluid' loading='lazy' /></div>
<div className="layer2">
<section className='bg-white p-4 m-4'>
<section className='mb-5'>
<h3 className='mb-3'>Lorem Ipsum Lore</h3>
<section className='mb-3 homeTeaser'>
	Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.
	</section>
</section>
<section className='text-center'><Button sx={{ fontWeight:'bold',  }} variant="contained" color="success" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">Read more</Button>
</section>
</section>

</div>
</div>
<div className="container_row"></div>
</section>



<section className="flex-fill px-4 mb-3 col-md-4">
<div className="container_row text-center">
<div className="layer1"><img src={E4} className='img-fluid' loading='lazy' /></div>
<div className="layer2">
<section className='bg-white p-4 m-4'>
<section className='mb-5'>
<h3 className='mb-3'>Lorem Ipsum Lore</h3>
<section className='mb-3 homeTeaser'>
	Lorem ipsum dolor sit amet consectetur adipiscing elit, tellus lacus parturient tempus magnis venenatis habitant interdum, nisi vehicula quis eu litora natoque.
	</section>
</section>
<section className='text-center'><Button sx={{ fontWeight:'bold',  }} variant="contained" color="success" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">Read more</Button>
</section>
</section>

</div>
</div>
<div className="container_row"></div>
</section>
</section>
<section className='d-flex flex-row-reverse'>
<Button sx={{ fontSize: 11, color: '#ffffff', fontWeight:'bold',  }} variant="contained" color="secondary" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">View All Events</Button>
</section>
</section>


<section className="defMrg">
<section className="d-flex flex-wrap p-5 mx-5">
<section className="flex-fill px-4 mb-5 col-md-4 d-none d-lg-block">
<div className="bg-info fullH">
<div className="container_row text-center">
<div className="layer1"><CardCon bg='primary' value={70} title='Jobs' IMG={J1} id='c6ha222' /></div>
</div>
<section className='text-center mt-2 mt-2 mb-3'>
<Button sx={{ fontSize: 11, color:'#F2F5F7', paddingLeft:2, paddingRight:2, fontWeight:'bold', border:'1px solid #F2F5F7',}} variant="text" color="warning" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">Learn more</Button>
</section>
</div>
</section>

<section className="flex-fill px-4 mb-5 col-md-4">
<div className="bg-info fullH">
<div className="container_row text-center">
<div className="layer1"><CardCon value={120} IMG={P1} title='Projects' id='c99ha222' /></div>
</div>
<section className='text-center mt-2 mt-2 mb-3'>
<Button sx={{ fontSize: 11, color:'#F2F5F7', paddingLeft:2, paddingRight:2, fontWeight:'bold', border:'1px solid #F2F5F7',}} variant="text" color="warning" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">Learn more</Button>
</section>
</div>
</section>

<section className="flex-fill px-4 mb-5 col-md-4">
<div className="bg-info fullH">
<div className="container_row text-center">
<div className="layer1"><CardCon value={160} IMG={CC1} title='Community Members' id='crt5ha222' />
</div>
</div>
<section className='text-center mt-2 mt-2 mb-3'>
<Button sx={{ fontSize: 11, color:'#F2F5F7', fontWeight:'bold', paddingLeft:2, paddingRight:2, border:'1px solid #F2F5F7',  }} variant="outlined" color="primary" endIcon={<ReadMoreIcon fontSize='10' />}  size="small">Learn more</Button>
</section>
</div>
</section>
</section>
</section>

</>
);}
